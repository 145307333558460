<template>
  <div class="d-flex justify-content-center">

    <div class="invoice-parent mb-3 mt-2 d-flex flex-column">

      <div class="logo-container">
        <img src="../../assets/logo.jpg" height="80" alt="logo">
      </div>

      <div class="my-container-light step-container my-2">
        <stepper :last-step="claim.lastStep" :beforeRouting="beforeRouting" :readonly="false" :model="steps" />
      </div>

      <router-view />

    </div>



  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Stepper from '@/components/primevue/stepper';
import { GO_TO_STEP, STEPS_ENUM } from '@/store/actions.type'

export default {
  name: 'claim',
  computed: {
    ...mapGetters(['claim'])
  },
  components: {
    Stepper
  },
  watch: {
    'claim.activeStep': async function (val) {
      await this.updateRoute()
    },
  },
  data() {
    return {
      steps: [
        {
          idx: STEPS_ENUM.PROFILE,
          label: 'Perfil',
          to: '/claim/profile'
        },
        {
          idx: STEPS_ENUM.INVOICES,
          label: 'Facturas',
          to: '/claim/invoices'
        },
        {
          idx: STEPS_ENUM.SIMULATION,
          label: 'Calculadora',
          to: '/claim/simulation'
        },
        {
          idx: STEPS_ENUM.END,
          label: 'Fin',
          to: '/claim/end'
        },

      ]
    };
  },
  methods: {
    async updateRoute() {
      // retrieve state from store and navigating to last stored step if necessary
      const next = this.steps.find(el=>el.idx === this.claim.activeStep)['to']
      if(this.$route.path !== next) {
        await this.$router.push(next)
      }
    },

    async beforeRouting({ idx }) {
      await this.$store.dispatch(GO_TO_STEP, idx)
    }

  },
  async mounted() {
    await this.updateRoute()

  }
}
</script>

<style>
.step-container {
}
.intro-container {
  font-family: "reclamatec",serif;
}

.invoice-parent {
  width: 550px;
}


.my-dropdown-items {
  background: ivory;
  border: 1px solid #CCC;
  font-size: 12px !important;
}
</style>